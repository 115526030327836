import React, { Component } from 'react';
import { Form, FormGroup, Input, Label, Button } from 'reactstrap';
import classes from './Contact.module.scss';
import axios from 'axios';

class Contact extends Component {
    constructor() {
        super()
        this.state = {
            name: '',
            email: '',
            message: ''
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleSubmit = async e => {
        e.preventDefault()

        const { name, email, message } = this.state;

        this.setState({
            name: '',
            email: '',
            message: ''
        })

        await axios.post('/api/form', {
            name,
            email,
            message
        })
    }

    render() {
        return (
            <div className={classes.Form}>
                <h2 className={classes.QuestionsCommentsLabel}>Questions or comments?</h2>
                <Form onSubmit={this.handleSubmit}>
                    <FormGroup>
                        <Label for='name'>Name:</Label>
                        <Input
                            value={this.state.name}
                            type="text"
                            name='name'
                            onChange={this.handleChange}>
                        </Input>
                    </FormGroup>

                    <FormGroup>
                        <Label for='email'>Email:</Label>
                        <Input
                            value={this.state.email}
                            type="email"
                            name='email'
                            onChange={this.handleChange}>
                        </Input>
                    </FormGroup>

                    <FormGroup>
                        <Label for='message'>Message:</Label>
                        <Input
                            value={this.state.message}
                            type="textarea"
                            name='message'
                            onChange={this.handleChange}>
                        </Input>
                    </FormGroup>
                    <Button>Submit</Button>
                </Form>
            </div>
        )
    }
}

export default Contact;
