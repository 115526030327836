import React, { Component } from 'react';
import classes from "./ProductsHero.module.scss";

import Markdown from 'markdown-to-jsx';
import PageData from '../../../assets/Data/Page-Data.json'

class ProductsHero extends Component {

    componentDidMount() {
        window.addEventListener('resize', this.updateScreenWidth.bind(this));
        window.scrollTo(0, 0)
    }

    state = {
        screenWidth: window.innerWidth
    }

    updateScreenWidth = () => {

        this.setState({
            screenWidth: window.innerWidth
        })
    }


    render() {

        let ProductPageData = this.props.page;
        let heroSection = null;
        let bgStyle = {
            backgroundImage: `url(${ProductPageData.HeroBG})`
        }

        if (this.state.screenWidth < 800) {
            heroSection = (

                <>
                    <div className={classes.TitleContainer} id="Hero">
                        <Markdown 
                        className={classes.Title} 
                        children={ProductPageData.Title}
                        ></Markdown>
                    </div>
                    <div className={classes.Container}>
                        <div className={classes.Bg}>
                            <div className={classes.BgImage} style={bgStyle} />
                        </div>
                    </div>

                    <div className={classes.TitleAndSubContainer}>
                        <Markdown
                            options={{ forceBlock: true }}
                            className={classes.SubTitle}
                            children={ProductPageData.SubTitle} 
                            />
                        <Markdown
                            options={{ forceBlock: true }}
                            className={classes.TagLine}
                            children={ProductPageData.TagLine} 
                            />
                        <div className={classes.WavyDivider}>
                            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                                <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className={classes.ShapeFill}></path>
                            </svg>
                        </div>
                    </div>
                </>
                // <div className={classes.Hero}>
                //     <div className={classes.TitleContainer} >
                //         <Markdown className={classes.Title} children={ProductPageData.Title}></Markdown>
                //     </div>
                //     <div className={classes.HeroContainer}>
                //         <div className={classes.HeroImage} style={bgStyle}>
                //         </div>
                //     </div>

                //     <div className={classes.TitleAndSubContainer}>
                //         <Markdown
                //             options={{ forceBlock: true }}
                //             className={classes.SubTitle}
                //             children={ProductPageData.SubTitle} />
                //         <Markdown
                //             options={{ forceBlock: true }}
                //             className={classes.TagLine}
                //             children={ProductPageData.TagLine} />
                //         <div className={classes.WavyDivider}>
                //             <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                //                 <path d="
                //                 M321.39,56.44
                //                 c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39
                //                 C823.78,31,906.67,72,985.66,92.83
                //                 c70.05,18.48,146.53,26.09,214.34,3
                //                 V0
                //                 H0V27.35
                //                 A600.21,600.21,0,0,0,321.39,56.44Z" className={classes.ShapeFill}></path>
                //             </svg>
                //         </div>
                //     </div>
                // </div>
            )
        }
        else {
            heroSection = (

                <>
                    <div className={classes.HeroContainer} id="Hero">
                        <div className={classes.Hero} style={bgStyle}>
                            <div className={classes.TitleAndSubContainer}>
                                <Markdown
                                    options={{ forceBlock: true }}
                                    className={classes.Title}
                                    children={ProductPageData.Title} />
                                <Markdown
                                    options={{ forceBlock: true }}
                                    className={classes.SubTitle}
                                    children={ProductPageData.SubTitle} />
                                <Markdown
                                    options={{ forceBlock: true }}
                                    className={classes.TagLine}
                                    children={ProductPageData.TagLine} />
                            </div>
                            <div className={classes.WavyDivider}>
                                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                                    <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className={classes.ShapeFill}></path>
                                </svg>
                            </div>
                        </div>
                    </div>

                </>

                // <div className={classes.Hero} >
                //     <div className={classes.HeroContainer} id="Hero">
                //         <div className={classes.HeroImage} style={bgStyle}>
                //             <div className={classes.TitleAndSubContainer}>
                //                 <Markdown
                //                     options={{ forceBlock: true }}
                //                     className={classes.Title}
                //                     children={ProductPageData.Title} />
                //                 <Markdown
                //                     options={{ forceBlock: true }}
                //                     className={classes.SubTitle}
                //                     children={ProductPageData.SubTitle} />
                //                 <Markdown
                //                     options={{ forceBlock: true }}
                //                     className={classes.TagLine}
                //                     children={ProductPageData.TagLine} />
                //             </div>
                //             <div className={classes.WavyDivider}>
                //                 <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                //                     <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className={classes.ShapeFill}></path>
                //                 </svg>
                //             </div>
                //         </div>
                //     </div>
                // </div>
            )
        }

        return (
            <>
                {heroSection}
            </>

        );
    }
};

export default ProductsHero;