import React, {useState} from "react";
import Proptypes from "prop-types";
import "./avatar-picker-styles/main-avatar.css";

MainAvatar.propTypes = {
  handleClick: Proptypes.func.isRequired,
  currentAvatar: Proptypes.object.isRequired
};

function MainAvatar({ handleClick, currentAvatar }) {

  const [avatarClicked, setAvatarClicked] = useState(false);

  const clickHandler = () => {
    handleClick();
    setAvatarClicked(true);
  }

  let avatarClasses = avatarClicked ? 'Avatar' : 'Avatar Pulse';
  return (
    <button className={avatarClasses} onClick={clickHandler}>
      <img
        src={require(`../avatars/${currentAvatar.src}`)}
        className="Avatar-img"
        alt={`MainAvatar: ${currentAvatar.label}`}
      />
    </button>
  );
}

export default MainAvatar;
