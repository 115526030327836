import React from 'react'
import Button from '../../Button/Button'
import { Link } from 'react-router-dom';
import classes from './ProductPageCard.module.scss'

const productPageCard = (props) => {

    return (
        <>
            <Link
                onClick={window.scrollTo(0, 0)}
                key={props.Link}
                to={`/Products/${props.Link}`}>
                <div className={classes.CardContainer}>
                <div className={classes.Card}>
                    <img className={classes.Image} src={props.image} alt={props.SubTitle} />
                    <h3 className={classes.ProductName}>{props.SubTitle}</h3>
                    <span className={classes.ProductDetails}>{props.Description}</span>
                </div>
                </div>
            </Link>
        </>
    )
}

export default productPageCard;