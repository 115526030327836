import React, { Component } from 'react'
import Markdown from 'markdown-to-jsx';

import classes from './Home.module.scss'
import ProductGrid from '../Content/Product/ProductGrid'
import HomeSection from './HomeSection';
import HomeHero from './HomeHero';

import PageData from '../../assets/Data/Home-Page-Data.json'

class Home extends Component {

    componentDidMount() {
        window.addEventListener('resize', this.updateScreenWidth.bind(this));
        window.scrollTo(0, 0)
    }

    state = {
        screenWidth: window.innerWidth
    }

    updateScreenWidth = () => {
        this.setState({
            screenWidth: window.innerWidth
        })
    }

    render() {

        let contentSections = PageData.Sections.map(item => {

            return (
            <HomeSection
                title={item.Title}
                titleColor={item.hasOwnProperty("TitleColor") ? item.TitleColor : "#ac3df6"}
                description={item.Description}
                additionalDetails={item.AdditionalDetails}
                border={item.Border}
                outline={item.Outline}
                imageOnLeft={item.hasOwnProperty("ImageOnLeft") ? item.ImageOnLeft : false}
                bg={item.BG}
                fg={item.FG}
                imageURL={item.ImageURL}
                buttonText={item.ButtonText}
                screenWidth={this.state.screenWidth}
            >
            </HomeSection>
            )
        })



        return (
            <>
                <HomeHero />
                {contentSections}
                <ProductGrid
                    Photos={PageData.Photos}
                    DescriptionIsButton={true}>
                </ProductGrid>
            </>
        )
    }
}

export default Home;