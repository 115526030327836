import React from 'react'
import Contact from '../Contact/Contact'

import classes from './Footer.module.scss'
import Logo from '../Toolbar/Logo/Logo'
import { Link } from 'react-router-dom';

const footer = (props) => {

    // window.onscroll = function () {
    //     var mediaShown = document.getElementsByClassName(classes.ShowMedia)

    //     if ((window.innerHeight + window.scrollY + 16) >= document.body.offsetHeight && mediaShown.length < 1) {
    //         var footerIcons = document.getElementsByClassName(classes.MediaWrapper);
    //         footerIcons[0].classList.add(classes.ShowMedia);
    //         var time = 0;
    //         for (let i = 0; i < footerIcons.length; i++) {
    //             setTimeout(() => {
    //                 footerIcons[i].classList.add(classes.ShowMedia);
    //             }, time);
    //             time = time + 200;
    //         }
    //     }
    // };

    var logoImgs = "/images/logos/";

    return (
        <footer className={classes.Footer}>
            <div className={classes.WavyBorder}>
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" className={classes.ShapeFill}></path>
                </svg>
            </div>

            <h2 className={classes.FooterHeader}>
                <img src={'/images/logos/Cakery-Logo-NoText.png'} />
                <span>Camille's Cakery</span>
            </h2>
            <h3 className={classes.QuickLinksHeader}>Quick Links</h3>
            <div className={classes.QuickLinksFlexContainer}>
                <div className={classes.QuickLinksColumn}>
                    <Link to="/About"><li className={classes.QuickLink}>About</li></Link>
                    <Link to="/Packages"><li className={classes.QuickLink}>Packages</li></Link>
                    <Link to="/Products"><li className={classes.QuickLink}>Products</li></Link>
                </div>
                <div className={classes.QuickLinksColumn}>
                <Link to="/Reviews"><li className={classes.QuickLink}>Reviews</li></Link>
                <Link to="/Gallery"><li className={classes.QuickLink}>Gallery</li></Link>
                <Link to="/Contact"><li className={classes.QuickLink}>Contact</li></Link>
                </div>
            </div >
            <div className={classes.MediaIconContainer}>
                <div className={classes.MediaIcon}>
                    <a href="mailto:CamzCakery@gmail.com?Subject=Hello" target="_top">
                        <img src={'/images/icons/white-mail-icon.png'} />
                    </a>
                </div>
                <div className={classes.MediaIcon}>
                    <a href="http://www.facebook.com/camillescakery">
                        <img src={'/images/icons/white-fb-icon.png'} />
                    </a>
                </div>
                <div className={classes.MediaIcon}>
                    <a href="http://www.instagram.com/camillescakery">
                        <img src={'/images/icons/white-ig-icon.png'} />
                    </a>
                </div>
            </div>
            <div className={classes.FootText}><p>© 2021 <strong>Camille's Cakery</strong> All Rights Reserved.</p>
                <p>CamillesCakery@gmail.com</p></div>
        </footer >
    )
}

export default footer;