import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import classes from './Reviews.module.scss'
import ReviewCard from "./ReviewCard";
import ReviewsHero from "./ReviewsHero";
import axios from "axios";

export default function Reviews() {


    const [reviewList, setReviewList] = useState([]);

    const reviews = reviewList.map(review => {
        return <ReviewCard
            title={review.Title}
            date={review.Date}
            description={review.Description}
            images={review.Images}
            name={review.Name}
            product={review.Product}
            avatar={review.Avatar}
        />
    })

    useEffect(() => {
        axios.get('https://wpbdpgqxi2.execute-api.us-west-1.amazonaws.com/test/get-testimonials/')
            .then((res) => {
                setReviewList(res.data.Items)
            })
            .catch((err) => {
                console.log("Error getting reviews")
            })
    }, [])

    return (
        <>
            <ReviewsHero></ReviewsHero>
            <section className={classes.CustomerReviewSection}>
                <h2 className={classes.CustomerReviewTitle}>Testimonials</h2>
                <p className={classes.CustomerReviewSubTitle}>Some impressions from our customers</p>
                {reviews}
            </section>


        </>
    )
}
