import React, { Component } from 'react'

import classes from './HomeSection.module.scss'



const homeSection = (props) => {

    const sectionContainerStyle = {
        background: props.fg,
        border: props.border,
        outline: props.outline || "none"

    };
    const sectionStyle = {
        background: props.bg
    };

    const titleStyles = {
        color : props.titleColor
    };

    const buttonStyles = {
        backgroundColor: props.titleColor
    }

    let sectionClasses = [classes.Section]
    let sectionContainerClasses = [classes.SectionContainer]
    let imageContainerClasses = [classes.SectionChild, classes.ImageContainer]

    if (!props.imageOnLeft) {
        sectionContainerClasses.push(classes.ReverseFlex);
        imageContainerClasses.push(classes.NoMarginLeft);
    }
    else imageContainerClasses.push(classes.NoMarginRight);
        
    let mainContent = null;
    if (props.screenWidth >= 550) {
        mainContent = (
            <section className={classes.Section} style={sectionStyle}>
                <div className={sectionContainerClasses.join(" ")} style={sectionContainerStyle}>
                    <div className={imageContainerClasses.join(" ")}>
                        <img src={props.imageURL} />
                    </div>
                    <div className={`${classes.SectionChild} ${classes.SectionContentContainer}`}>
                        <h3 className={classes.SectionContentTitle} style={titleStyles}>{props.title}</h3>
                        <p className={classes.SectionContent}>{props.description}</p>
                        <button className={classes.MoreButton} style={buttonStyles}>{props.buttonText}</button>
                    </div>
                </div>
            </section>
        )
    }
    else {
        mainContent = (
            <section className={sectionClasses.join(" ")} style={sectionStyle}>
                <div className={sectionContainerClasses.join(" ")} style={sectionContainerStyle} >
                    <div className={`${classes.SectionContentContainer}`}>
                        <h3 className={classes.SectionContentTitle} style={titleStyles}>{props.title}</h3>
                        <p className={classes.SectionContent}>{props.description}</p>
                        <div className={`${classes.SectionChild} ${classes.ImageContainer}`}>
                            <img src={props.imageURL} />
                            <div className={classes.MoreButtonContainer} >
                                <button className={classes.MoreButton} style={buttonStyles}>{props.buttonText}</button>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        )
    }
    return (
        <>
        { mainContent }
        </>
    )
}

export default homeSection;