import React, { Component } from 'react';
import classes from "./ReviewsHero.module.scss";

import Markdown from 'markdown-to-jsx';
import PageData from '../../assets/Data/Testimonial-Page-Data.json'
import ReviewsFormButton from './ReviewsFormButton';

class ReviewsHero extends Component {

    componentDidMount() {
        window.addEventListener('resize', this.updateScreenWidth.bind(this));
        window.scrollTo(0, 0)
    }

    state = {
        screenWidth: window.innerWidth
    }

    updateScreenWidth = () => {
        this.setState({
            screenWidth: window.innerWidth
        })
    }

    render() {


        let heroSection = null;
        let bgStyle = {
            backgroundImage: `url(${PageData.HeroBG})`
        }

        if (this.state.screenWidth < 800) {
            heroSection = (
                <div id="Hero">
                    <div className={classes.TitleContainer}>
                        <Markdown className={classes.Title} children={PageData.Title}></Markdown>
                    </div>
                    <div className={classes.Container}>
                        <div className={classes.Bg}>
                            <div className={classes.BgImage} style={bgStyle} />
                        </div>

                    </div>

                    <div className={classes.OverlayContainer}>
                        <ReviewsFormButton />
                    </div>
                </div>
            )
        }
        else {
            heroSection = (
                <div id="Hero">
                    <div className={classes.Container}>
                        <div className={classes.Bg}>
                            <div className={classes.BgImage} style={bgStyle} />
                        </div>
                        <div className={classes.TitleContainer}>
                            <Markdown className={classes.Title} children={PageData.Title}></Markdown>
                        </div>
                        <div></div>
                        <Markdown className={classes.Subtitle} children={PageData.SubTitle}></Markdown>
                    </div>
                    <div className={classes.OverlayContainer}>
                        <ReviewsFormButton />
                    </div>
                </div>)
        }

        return (
            <>
                {heroSection}
            </>

        );
    }
};

export default ReviewsHero;