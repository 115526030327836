import React, { Component } from 'react';
import classes from "./HomeHero.module.scss";

import Markdown from 'markdown-to-jsx';
import PageData from '../../assets/Data/Home-Page-Data.json'

class HomeHero extends Component {

    componentDidMount() {
        window.addEventListener('resize', this.updateScreenWidth.bind(this));
        window.scrollTo(0, 0)
    }

    state = {
        screenWidth: window.innerWidth
    }

    updateScreenWidth = () => {

        this.setState({
            screenWidth: window.innerWidth
        })
    }
    render() {

        console.log(this.state.screenWidth)

        let heroSection = null;
        let bgStyle = {
            backgroundImage: `url(${PageData.HeroBG})`
        }

        if (this.state.screenWidth < 800) {
            heroSection = (
                <>
                    <div className={classes.TitleContainer}>
                        <Markdown className={classes.Title} children={PageData.Title}></Markdown>
                    </div>
                    <div className={classes.Container}>
                        <div className={classes.Bg}>
                            <div className={classes.BgImage} style={bgStyle} />
                        </div>

                    </div>

                    <div className={classes.OverlayContainer}>
                        <button className={classes.Button}>
                            <span >
                                <span className={classes.ImageContainer}>
                                    <img className={classes.MailIcon} src={"/images/icons/mail-icon-white.png"} alt="Mail Icon" />
                                </span>{PageData.Button.Text}
                            </span>
                        </button>
                    </div>
                </>
            )
        }
        else {
            heroSection = (
                <>
                    <div className={classes.Container} id="Hero">
                        <div className={classes.Bg}>
                            <div className={classes.BgImage} style={bgStyle} />
                        </div>
                        <div className={classes.TitleContainer}>
                            <Markdown className={classes.Title} children={PageData.Title}></Markdown>
                        </div>
                        <Markdown className={classes.Subtitle} children={PageData.SubTitle}></Markdown>
                    </div>
                    <div className={classes.OverlayContainer}>
                        <button className={classes.Button}>
                            <span>
                                <span className={classes.ImageContainer}>
                                    <img className={classes.MailIcon} src={"/images/icons/mail-icon-white.png"} alt="Mail Icon" />
                                </span>{PageData.Button.Text}
                            </span>
                        </button>
                    </div>
                </>
            )
        }

        return (
            <>
                {heroSection}
            </>

        );
    }
};

export default HomeHero;