import React, { Component } from 'react';
import classes from './MiniToolbar.module.scss';
import Hamburger from './Hamburger/Hamburger'
import NavigationItems from './NavigationItems/NavigationItems';
import Logo from './Logo/Logo';
import ToolbarItems from '../../assets/Data/Toolbar-Data.json'
import Aux from '../../Hoc/Aux'

const navItems = ToolbarItems;

class MiniToolbar extends Component {

    componentDidMount() {
        window.addEventListener('scroll', this.updateToolbarScrollPosition.bind(this))
    }

    state = {
        showSubToolbar: false
    }

    updateToolbarScrollPosition() {

        if (document.getElementById("Hero") != null) {
            var topofDiv = document.getElementById("Hero").offsetTop; //gets offset of header
            var height = document.getElementById("Hero").offsetHeight; //gets height of header

            if (window.scrollY >= topofDiv + height && !this.state.showSubToolbar) {
                this.setState({ showSubToolbar: true })
            }
            if (window.scrollY < topofDiv + height && this.state.showSubToolbar) {
                this.setState({ showSubToolbar: false })
            }
        }
    }

    render() {

        let toolbarContainerClasses = [classes.SubToolbarContainer]
        let toolbarClass = null;

        if (this.state.showSubToolbar) {
            toolbarContainerClasses = [classes.SubToolbarContainer, classes.SubToolbarDisplay]
            toolbarClass = classes.SubToolbar;
        }


        let navigationItems = (
            <NavigationItems
                navItems={navItems}
                // centeredLogo={!this.state.burgerIconDisplayed}
                centeredLogo={false}
                sideMenuIsOpen={this.state.showBurgerMenu}
                burgerIconDisplayed={this.state.burgerIconDisplayed}
                toggleBurger={this.toggleBurger}>
                <Logo
                    height="50px"
                    source="/images/logos/Cakery-Logo-NoText.png" />
            </ NavigationItems>
        )

        let menu = this.state.showSubToolbar ? (
            <>
                {navigationItems}
            </>
        ) : null


        return (
            <Aux>
                <div className={toolbarContainerClasses.join(" ")}>
                    <header className={toolbarClass} >
                        {menu}
                    </header>
                </div>
            </Aux>
        )
    }
}

export default MiniToolbar;