import React, { Component } from 'react'

import classes from './Packages.module.scss';
import PageData from "../../assets/Data/Packages-Page-Data.json";

import PackagesHero from './PackagesHero';

class Packages extends Component {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateScreenWidth.bind(this));
        window.scrollTo(0, 0)
    }

    state = {
        screenWidth: window.innerWidth
    }

    updateScreenWidth = () => {

        this.setState({
            screenWidth: window.innerWidth
        })
    }

    render() {
        let packages = null;
        if (this.state.screenWidth >= 550) {

            packages = (
                <div className={classes.Packages}>
                    <div className={`${classes.OuterFlexRow} ${classes.Outer1}`}>
                        <div className={classes.Col1}></div>
                        <div className={classes.InnerFlexRow}>
                            <div className={`${classes.Details} ${classes.InnerFlexColumn}`}>
                                <h2 className={classes.PackageTitle}>Party Package</h2>
                                <h3 className={classes.PackagePricing}>Starting at <span className={classes.BoldPricing}>$90</span></h3>
                                <span style={{ display: "none" }} className={classes.PackageSavings}>Save <span className={classes.BigSavings}>$5</span></span>

                                <div className={classes.ListContainer}>
                                    <ul>
                                        <li><strong>1 dozen</strong> cupcakes<i> or </i><strong>1.5 dozen</strong> mini cupcakes</li>
                                        <li><strong>1 dozen</strong> cake pops</li>
                                        <li><strong>1 dozen</strong> pretzel rods dipped in chocolate</li>
                                        <li><strong>1 dozen</strong> cookies of your choice</li>
                                    </ul>
                                </div>
                            </div>
                            <div className={`${classes.InnerFlexColumn} ${classes.ImageColumn}`}>
                                <div className={classes.PartyImage}>
                                    <img src="/images/products/packages/Party-Package-Cupcake.png" alt="Party Package 1" />
                                </div>
                            </div>
                        </div>
                        <div className={classes.Col3}></div>
                    </div>
                    <div className={`${classes.OuterFlexRow} ${classes.Outer2}`}>
                        <div className={classes.Col1}></div>
                        <div className={classes.InnerFlexRow}>
                            <div className={`${classes.InnerFlexColumn} ${classes.ImageColumn}`}>
                                <div className={classes.PartyImage}>
                                    <img src="/images/products/packages/Double-Party-Pack-Cupcake.png" alt="Party Package 1" />
                                </div>
                            </div>
                            <div className={`${classes.Details} ${classes.InnerFlexColumn}`}>
                                <h2 className={classes.PackageTitle}>Double Party Pack</h2>
                                <h3 className={classes.PackagePricing}>Starting at <span className={classes.BoldPricing}>$165</span></h3>
                                <span className={classes.PackageSavings}>Save <span className={classes.BigSavings}>$21</span></span>

                                <div className={classes.ListContainer}>
                                    <ul>
                                        <li><strong>2 dozen</strong> cupcakes<i> or </i><strong>3 dozen</strong> mini cupcakes</li>
                                        <li><strong>2 dozen</strong> cake pops</li>
                                        <li><strong>2 dozen</strong> pretzel rods dipped in chocolate</li>
                                        <li><strong>2 dozen</strong> cookies of your choice</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className={classes.Col3}></div>
                    </div>

                    <div className={`${classes.OuterFlexRow} ${classes.Outer3}`}>
                        <div className={classes.Col1}></div>
                        <div className={classes.InnerFlexRow}>
                            <div className={`${classes.Details} ${classes.InnerFlexColumn}`}>
                                <h2 className={classes.PackageTitle}>Deluxe Package</h2>
                                <h3 className={classes.PackagePricing}>Starting at <span className={classes.BoldPricing}>$235</span></h3>
                                <span className={classes.PackageSavings}>Save <span className={classes.BigSavings}>$44</span></span>

                                <div className={classes.ListContainer}>
                                    <ul>
                                        <li><strong>3 dozen</strong> cupcakes<i> or </i><strong>4.5 dozen</strong> mini cupcakes</li>
                                        <li><strong>3 dozen</strong> cake pops</li>
                                        <li><strong>3 dozen</strong> pretzel rods dipped in chocolate</li>
                                        <li><strong>3 dozen</strong> cookies of your choice</li>
                                    </ul>
                                </div>
                            </div>
                            <div className={`${classes.InnerFlexColumn} ${classes.ImageColumn}`}>
                                <div className={classes.PartyImage}>
                                    <img src="/images/products/packages/Deluxe-Package-Cupcake.png" alt="Party Package 1" />
                                </div>
                            </div>
                        </div>
                        <div className={classes.Col3}></div>
                    </div>
                </div>
            )
        }
        else {

            packages = (
                <div className={classes.Packages}>
                    <div className={`${classes.OuterFlexRow} ${classes.Outer1}`}>
                        <div className={classes.InnerFlexRow}>
                            <div className={`${classes.Details} ${classes.InnerFlexColumn}`}>
                                <h2 className={classes.PackageTitle}>Party Package</h2>
                                <h3 className={classes.PackagePricing}>Starting at <span className={classes.BoldPricing}>$90</span></h3>
                                <span style={{ display: "none" }} className={classes.PackageSavings}>Save <span className={classes.BigSavings}>$5</span></span>

                                <div className={classes.ListContainer}>
                                    <ul>
                                        <li><strong>1 dozen</strong> cupcakes<i> or </i><strong>1.5 dozen</strong> mini cupcakes</li>
                                        <li><strong>1 dozen</strong> cake pops</li>
                                        <li><strong>1 dozen</strong> pretzel rods dipped in chocolate</li>
                                        <li><strong>1 dozen</strong> cookies of your choice</li>
                                    </ul>
                                </div>
                            </div>
                            <div className={`${classes.InnerFlexColumn} ${classes.ImageColumn}`}>
                                <div className={classes.PartyImage}>
                                    <img src="/images/products/packages/Party-Package-Cupcake.png" alt="Party Package 1" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`${classes.OuterFlexRow} ${classes.Outer2}`}>
                        <div className={classes.InnerFlexRow}>

                            <div className={`${classes.Details} ${classes.InnerFlexColumn}`}>
                                <h2 className={classes.PackageTitle}>Double Party Pack</h2>

                                <div className={classes.PriceFlexContainer}>
                                    <h3 className={classes.PackagePricing}>Starting at <span className={classes.BoldPricing}>$165</span></h3>
                                    <span className={classes.PackageSavings}>Save <span className={classes.BigSavings}>$21</span></span>
                                </div>

                                <div className={classes.ListContainer}>
                                    <ul>
                                        <li><strong>2 dozen</strong> cupcakes<i> or </i><strong>3 dozen</strong> mini cupcakes</li>
                                        <li><strong>2 dozen</strong> cake pops</li>
                                        <li><strong>2 dozen</strong> pretzel rods dipped in chocolate</li>
                                        <li><strong>2 dozen</strong> cookies of your choice</li>
                                    </ul>
                                </div>
                            </div>
                            <div className={`${classes.InnerFlexColumn} ${classes.ImageColumn}`}>
                                <div className={classes.PartyImage}>
                                    <img src="/images/products/packages/Double-Party-Pack-Cupcake.png" alt="Party Package 1" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`${classes.OuterFlexRow} ${classes.Outer3}`}>
                        <div className={classes.InnerFlexRow}>
                            <div className={`${classes.Details} ${classes.InnerFlexColumn}`}>
                                <h2 className={classes.PackageTitle}>Deluxe Package</h2>
                                <div className={classes.PriceFlexContainer}>
                                    <h3 className={classes.PackagePricing}>Starting at <span className={classes.BoldPricing}>$235</span></h3>
                                    <span className={classes.PackageSavings}>Save <span className={classes.BigSavings}>$44</span></span>
                                </div>
                                <div className={classes.ListContainer}>
                                    <ul>
                                        <li><strong>3 dozen</strong> cupcakes<i> or </i><strong>4.5 dozen</strong> mini cupcakes</li>
                                        <li><strong>3 dozen</strong> cake pops</li>
                                        <li><strong>3 dozen</strong> pretzel rods dipped in chocolate</li>
                                        <li><strong>3 dozen</strong> cookies of your choice</li>
                                    </ul>
                                </div>
                            </div>

                            <div className={`${classes.InnerFlexColumn} ${classes.ImageColumn}`}>
                                <div className={classes.PartyImage}>
                                    <img src="/images/products/packages/Deluxe-Package-Cupcake.png" alt="Party Package 1" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        let aLaCarte = (
            <>
                <div className={classes.AddOnBorder}>
                    <div className={classes.AddOnContainer}>
                        <h2 className={classes.AddOnTitle}>Add-on Options:</h2>
                        <ul className={classes.AddOnList}>
                            <li className={classes.AddOnItem}><strong>$30</strong> per dozen of regular size <strong>cupcakes</strong> on any package ordered</li>
                            <li className={classes.AddOnItem}><strong>$20</strong> per dozen <strong>mini cupcakes</strong> on any package ordered</li>
                            <li className={classes.AddOnItem}>
                                <strong>$30</strong> per dozen of <strong>cake pops</strong> on any package ordered</li>
                            <li className={classes.AddOnItem}>
                                <strong>$18</strong> per dozen of <strong>chocolate dipped pretzel rods</strong> on any package ordered</li>
                            <li className={classes.AddOnItem}>
                                <strong>$15</strong> per dozen of <strong>cookies</strong> on any package ordered</li>
                            <li className={classes.AddOnItem}>
                                <strong>$25</strong> per dozen <strong>chocolate dipped strawberries</strong> on any package ordered</li>
                        </ul>
                    </div>
                </div>
            </>
        )

        return (
            <>
                <PackagesHero />
                {packages}
                {aLaCarte}
            </>
        )
    }
}

export default Packages;