import React from 'react'

import classes from './NavigationItems.module.scss';
import NavigationItem from '../NavigationItem/NavigationItem'
import Logo from '../Logo/Logo'
import Aux from "../../../Hoc/Aux"
import { Link } from 'react-router-dom';
import Hamburger from '../Hamburger/Hamburger';


const navigationItems = (props) => {

    let inMobileView = props.burgerIconDisplayed;
    // populate list of items in NavBar
    const navItems = Object.keys(props.navItems)
        .map((navKey, index) => {

            let subItems = null
            let navItemStyling = null
            let navItemIcon = null
            if (Array.isArray(props.navItems[navKey])) {
                subItems = props.navItems[navKey]
            }

            if (typeof props.navItems[navKey] === 'object' && props.navItems[navKey] !== null) {
                let objs = Object.keys(props.navItems[navKey])
                if (objs.includes("Submenu")){
                    subItems = props.navItems[navKey]["Submenu"]
                }
                if (objs.includes("Styled")) {
                    navItemStyling = props.navItems[navKey]["Styled"]
                }
                if (objs.includes("Icon")) {
                    navItemIcon = props.navItems[navKey]["Icon"]
                }
            }
            return (
                <li key={index} className={classes.ListItem}>
                    <NavigationItem
                        key={navKey}
                        navItemName={navKey}
                        sideMenuIsOpen={props.sideMenuIsOpen}
                        navSubItems={subItems}
                        toggleBurger={props.toggleBurger}
                        navItemStyling={navItemStyling}
                        navItemIcon={navItemIcon}
                        >
                    </NavigationItem>
                </li>
            )
        });

    // Prepare NavBar with logo position
    let right = null, left = null, center = null;

    let logoPosition = props.centeredLogo ? navItems.length / 2 : 0;

    if (!inMobileView) {
        navItems.splice(logoPosition, 0,
            <Link
                // onClick={window.scrollTo(0, 0)}
                key={logoPosition}
                to={"/"}>
                <span
                    className={classes.ListItemLogo}
                    key={"logo"}>
                    {props.children}
                </span >
            </Link>
        )
    }

    // Check if logo should be centered and if in mobile view
    let navAdjusted = null;
    if (props.centeredLogo && !inMobileView) {

        left = navItems.splice(0, Math.floor(navItems.length / 2));
        center = navItems.splice(0, 1);
        right = navItems;

        navAdjusted = (
            <nav className={[classes.Menu, (props.sideMenuIsOpen ? null : classes.Hide)].join(" ")}>
                <ul className={classes.List}>
                    <div className={classes.Left}>{left}</div>
                    <div className={classes.Center}>{center}</div>
                    <div className={classes.Right}>{right}</div>
                </ul>
            </nav>
        )
    } else if (!props.centeredLogo && !inMobileView) {
        navAdjusted = (
            <nav className={[classes.Menu, (props.sideMenuIsOpen ? null : classes.Hide)].join(" ")}>
                <ul className={classes.List}>
                    {navItems}
                </ul>
            </nav>
        )
    } else {
        navAdjusted = (
            <nav className={[classes.Menu, (props.sideMenuIsOpen ? null : classes.Hide)].join(" ")}>
                <div className={classes.FirstRow}>
                    <Link
                        // onClick={window.scrollTo(0, 0)}
                        key={logoPosition}
                        to={"/"}>
                        <span
                            className={classes.ListItemLogo}
                            key={"logo"}>
                            <Logo
                                className={classes.Logo}
                                height={"50px"}
                            />
                        </span >
                    </Link>
                    <div className={classes.HamburgerContainer} >
                        <Hamburger
                            toggleBurger={props.toggleBurger}
                            sideMenuIsOpen={true}
                        />
                    </div>
                </div>
                <ul className={classes.List}>{navItems}</ul>
            </nav>
        )
    }

    return (
        <Aux>
            {navAdjusted}
        </Aux>
    )
};

export default navigationItems;