import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import gsap from 'gsap';
import classes from './ReviewsFormButton.module.scss'
import ReviewForm from "./ReviewForm";

export default function ReviewsFormButton() {

    const [formOpen, setFormOpen] = useState(false);
    const [moveFormDown, setMoveFormDown] = useState(false)
    const [whiteOutForm, setWhiteOutForm] = useState(false)
    // let [demoRef, setDemoRef] = useState({});
    let reviewButtonContainerRef = useRef();
    let reviewButtonRef = useRef();
    let reviewButtonTextRef = useRef();
    let reviewFormRef = useRef();
    

    const { current: tl } = useRef(gsap.timeline({ paused: true }));

    useEffect(() => {

        tl
            .to(reviewButtonRef.current, { width: "clamp(375px, 100%, 1200px", height: "100%", backgroundColor: "#f0f8f9", duration: .45, cursor: "auto", ease: "power3.easeIn", border: "0px solid #22C6DD", padding: "2.5rem .25rem"}) 
            .to(reviewButtonTextRef.current, { display: "none", duration: .01 })
            .to(reviewFormRef.current, { display: "block", duration: .1 })
            .add(() => reviewButtonContainerRef.current.scrollIntoView({behavior: "smooth"}) )
            .to(reviewFormRef.current, { opacity: 1, y: 0, border: "0px", borderColor: "#f0f8f9", duration: .3 })
    }, [tl])


    const playWriteReviewAnimation = useCallback(() => {
        if (!formOpen) {
            setMoveFormDown(true)
            tl.play();
            setFormOpen(o => !o)
        }
    }, [formOpen, tl])

    const reverseWriteReviewAnimation = useCallback(() => {
        if (formOpen) {
            tl.reverse(.3);
            reviewButtonContainerRef.current.scrollIntoView({behavior: "smooth", block: "center"})

            setFormOpen(o => !o)
            setMoveFormDown(false)
        }
    }, [formOpen, tl])

    let ReviewRequestButtonClasses = [classes.ReviewRequestButton]
    if (!formOpen) ReviewRequestButtonClasses.push(classes.ButtonHoverable)
    else ReviewRequestButtonClasses = ReviewRequestButtonClasses.filter(item => item == classes.ReviewRequestButton)

    let ReviewRequestFormButtonContainerClasses = [classes.ReviewRequestButtonContainer]
    if (moveFormDown) ReviewRequestFormButtonContainerClasses.push(classes.MoveFormDown)
    else ReviewRequestFormButtonContainerClasses = ReviewRequestFormButtonContainerClasses.filter(item => item != classes.MoveFormDown)

    return (
        <div className={ReviewRequestFormButtonContainerClasses.join(" ")}
            ref={el => reviewButtonContainerRef.current = el}
        >
            {whiteOutForm ? <div className={classes.ReviewRequestButtonContainerWhiteOut}></div> : null}
            <div
                className={ReviewRequestButtonClasses.join(" ")}
                ref={el => reviewButtonRef.current = el}
                onClick={playWriteReviewAnimation}>
                <span
                    className={classes.ReviewRequestButtonText}
                    ref={el => reviewButtonTextRef.current = el} >Share your feedback!</span>
                <div
                    ref={el => reviewFormRef.current = el}
                    className={classes.ReviewRequestFormContainer}>

                    <ReviewForm
                        closeButtonHandler={reverseWriteReviewAnimation}
                        newReviewLoading={setWhiteOutForm}
                    />

                </div>
            </div>
        </div>
    )
}
