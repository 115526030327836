import React, { useState, useEffect, useRef, useCallback } from 'react'
import classes from "./ReviewCard.module.scss"
import Aux from "../../Hoc/Aux"

const ReviewCard = (props) => {

    let [screenWidth, setScreenWidth] = useState(window.innerWidth)

    let setScreenWidthListener = useCallback(() => {
        window.addEventListener('resize', () => setScreenWidth(window.innerWidth))
    })
    let removeScreenWidthListener = useCallback(() => {
        window.removeEventListener('resize', () => setScreenWidth(window.innerWidth))
    })
    useEffect(() => {
        setScreenWidthListener()
        return () => removeScreenWidthListener()
    }, [])

    //   // Create a ref that stores handler
    //   const savedHandler = useRef();

    //   // Update ref.current value if handler changes.
    //   // This allows our effect below to always get latest handler ...
    //   // ... without us needing to pass it in effect deps array ...
    //   // ... and potentially cause effect to re-run every render.
    //   useEffect(() => {
    //     savedHandler.current = handler;
    //   }, [handler]);

    //   useEffect(
    //     () => {

    //       const eventListener = event => savedHandler.current(event);

    //       window.addEventListener('resize', eventListener);

    //       return () => {
    //         window.removeEventListener('resize', eventListener);
    //       };
    //     },
    //     [] 
    //   );

    const thumbnails = props.images.map(item => {
        return (<a key={item} href={`https://camilles-cakery-image-upload-bucket.s3-us-west-1.amazonaws.com/${item}`}><img className={classes.Thumbnail} src={`https://camilles-cakery-image-upload-bucket-resized.s3-us-west-1.amazonaws.com/resized-${item}`} alt="thumnbnail" /></a>)
    })

    return (
        <>
            {screenWidth >= 750 ?
                <div className={classes.ReviewContainer}>
                    <div className={classes.ReviewTab}><div className={classes.ReviewTabQuotes}>,,</div></div>
                    <div className={classes.ReviewInner}>
                        <div className={classes.FlexReviewContainer}>
                            <div className={classes.FlexProfile}>
                                <img className={classes.Avatar} src={`/images/icons/avatars/avatar${props.avatar}.png`} />
                                <span className={classes.Name}>{props.name}</span>
                            </div>
                            <div className={classes.ReviewContent}>
                                <h3 className={classes.Title}>{props.title}</h3>
                                <div className={classes.Product}>
                                    <div className={classes.ProductTitle}><i>Review of <b>{props.product}</b></i></div>
                                    <div className={classes.Date}>{props.date}</div>
                                </div>
                                <p className={classes.Description}>{props.description}</p>

                                <div className={classes.FlexThumbnail} >
                                    {thumbnails}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className={classes.ReviewContainer}>
                    <div className={classes.ReviewTab}><div className={classes.ReviewTabQuotes}>,,</div></div>
                    <div className={classes.ReviewInner}>

                        <div className={classes.ReviewContent}>
                            <h3 className={classes.Title}>{props.title}</h3>
                            <div className={classes.Product}>
                                <div className={classes.ProductTitle}><i>Review of <b>{props.product}</b></i></div>
                                <div className={classes.Date}>{props.date}</div>
                            </div>
                            <p className={classes.Description}>{props.description}</p>

                            <div className={classes.FlexThumbnail} >
                                {thumbnails}
                            </div>
                            <div className={classes.FlexProfile}>
                                <img className={classes.Avatar} src={`/images/icons/avatars/avatar${props.avatar}.png`} />
                                <span className={classes.Name}>{props.name}</span>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default ReviewCard;