import React, { useState, useEffect } from 'react'
import { Form, FormGroup, Input, Label, Button, Col, Row } from 'reactstrap';
import axios from 'axios';

import classes from './ContactPage.module.scss';

export default () => {

    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        contactNumber: "",
        reason: "Order with Camille's Cakery",
        product: "",
        description: ""
    })
    const [contactSuccess, setContactSuccess] = useState(false)
    const [contactSent, setContactSent] = useState(false)
    

    const handleChange = e => {
        console.log(e.target)
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleSubmit = async e => {
        e.preventDefault()

        const { firstName, lastName, email, contactNumber, reason, product, description } = formData;

        setFormData({
            firstName: "",
            lastName: "",
            email: "",
            contactNumber: "",
            reason: "Order with Camille's Cakery",
            otherProduct: null,
            description: ""
        })

        const contactApiUrl = 'https://8dp6pie6n4.execute-api.us-west-1.amazonaws.com/dev/contact-us'


        const response = await axios.post(contactApiUrl, {    
            firstName,
            lastName,
            email,
            contactNumber,
            reason,
            product,
            description}).then((res) => {
            console.log(res)
            return res;
        }).then((res) => {
            setContactSent(true)
            setContactSuccess(true)
        }).catch((err) => {
            setContactSent(true)
            setContactSuccess(false)
            console.log(err)
        })
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
        {!contactSent ?
        <section className={classes.ContactUsSection}>
            <div className={classes.ContactUsSectionContainer}>
                <div className={classes.HeadingContainer}>
                    <h2 className={classes.ContactHeading}>Contact Us</h2>
                    <p className={classes.ContactSubHeading}>Looking to place an order? Reach out and let us know what we can do for you today.</p>
                </div>
                <div className={classes.Form} >
                    <Form onSubmit={handleSubmit}>
                        <Row form>
                            <Col md={6}>
                                <FormGroup className="px-1">
                                    <Label className={classes.Required} for='firstName'>First Name:</Label>
                                    <Input
                                        required
                                        value={formData.firstName}
                                        type="text"
                                        name='firstName'
                                        onChange={handleChange}>
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup className="px-1">
                                    <Label className={classes.Required} for='lastName'>Last Name:</Label>
                                    <Input
                                        required
                                        value={formData.lastName}
                                        type="text"
                                        name='lastName'
                                        onChange={handleChange}>
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row form>
                            <Col md={6}>
                                <FormGroup className="px-1">
                                    <Label className={classes.Required} for='email'>Email:</Label>
                                    <Input
                                        required
                                        value={formData.email}
                                        type="email"
                                        name='email'
                                        onChange={handleChange}>
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup className="px-1">
                                    <Label for='contactNumber'>Contact Number:</Label>
                                    <Input
                                        value={formData.contactNumber}
                                        type="tel"
                                        name='contactNumber'
                                        onChange={handleChange}>
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row form>
                            <Col md={6}>
                                <FormGroup className="px-1">
                                    <Label className={classes.Required} for='reason'>Reason for writing:</Label>
                                    <Input
                                        required
                                        type="select"
                                        name="reason"
                                        id="reason"
                                        onChange={handleChange}
                                        value={formData.reason}>
                                        <option>Order with Camille's Cakery</option>
                                        <option>Inquire about custom products</option>
                                        <option>Comment or Concern</option>
                                        <option>Other</option>
                                    </Input>
                                </FormGroup>
                            </Col>

                            {formData.reason == "Order with Camille's Cakery" ?
                                <Col md={6}>
                                    <FormGroup className="px-1">
                                        <Label className={classes.Required} for='product'>Product:</Label>
                                        <Input
                                            required
                                            value={formData.product}
                                            type="select"
                                            name="product"
                                            id="product"
                                            onChange={handleChange}>
                                            <option>Party Package</option>
                                            <option>Cupcakes</option>
                                            <option>Cake(s)</option>
                                            <option>Cakepops</option>
                                            <option>Cookies</option>
                                            <option>Specialty Sweets</option>
                                            <option>Other</option>
                                        </Input>
                                    </FormGroup>
                                </Col> : null}
                        </Row>
                        <FormGroup className="px-1">
                            <Label for='description'>Description:</Label>
                            <Input

                                placeholder="Provide a brief description"
                                value={formData.description}
                                type="textarea"
                                name='description'
                                onChange={handleChange}>
                            </Input>
                        </FormGroup>
                        <Button className={`${classes.Button} ${classes.Submit}`}>Submit</Button>
                    </Form>
                </div>
            </div>
        </section> 
        : contactSuccess ? 
            <h2>Thank you.</h2> : <h2>Something went wrong while trying to save your info. Please try direct emailing to CamillesCakery@gmail.com. We hope to hear from you.</h2>
        }
        </>
    )
}
