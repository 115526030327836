import React, { Component } from 'react'

import classes from './ProductGrid.module.scss';
import ProductCard from './ProductCard';
import ProductPageCard from './ProductPageCard';
import Aux from '../../../Hoc/Aux';

class productGallery extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        let productList = [];
        if (this.props.Photos) {
            for (let n in this.props.Photos) {

                const cardProperties = {
                    key:n,
                    className:classes.GridItem,
                    image: "/images/products/" + this.props.Photos[n].Image,
                    SubTitle:this.props.Photos[n].SubTitle,
                    Link:this.props.Photos[n].Link,
                    Description:this.props.Photos[n].Description ? this.props.Photos[n].Description : null
                }

                if (this.props.ProductsPage) {
                    productList.push(<ProductPageCard {...cardProperties} />)
                }
                else {
                    productList.push(<ProductCard {...cardProperties} />)
                }
            }
        }


        return (
            <Aux>
                <section className={classes.ProductGridContainer}>
                    <section className={classes.ProductGrid}>
                        {productList}
                    </section>
                </section>
            </Aux>
        )
    }
}

export default productGallery;