import React, { Component } from 'react'
import { Link } from 'react-router-dom';


import classes from './NavigationItem.module.scss';
import Aux from '../../../Hoc/Aux';

class navigationItem extends Component {

    componentDidUpdate() {
        // if (!this.props.sideMenuIsOpen)
            // this.closeSubmenu();
    }

    state = {
        hideSubToolbar : false
    }

    submenuClasses = [classes.SubMenuContainer]
    anchorLinkClasses = [classes.NavAnchor]

    subToolBarElement = (<img className={classes.subMenuImage} src="/images/products/top/cake.jpg" alt=""></img>)

    // toggleSubmenu = () => {
    //     if (window.innerWidth <= 750) {
    //         if (this.submenuClasses.includes(classes.Open)) this.submenuClasses.pop(classes.Open);
    //         else this.submenuClasses.push(classes.Open);
    //     }
    //     this.setState({});
    // }

    closeSubmenu = () => {
        if (!this.props.sideMenuIsOpen && this.submenuClasses.includes(classes.Open)) {
            this.submenuClasses.pop(classes.Open);
            this.setState({});
        }
    }

    updateSubToolbarImage = (imageSource) => {
        if (this.subToolBarElement !== (<img className={classes.subMenuImage} src={imageSource} alt=""></img>)) {
            this.subToolBarElement = (<img className={classes.subMenuImage} src={imageSource} alt=""></img>)
            this.setState({});
        }
        if (imageSource == null) {
            this.subToolBarElement = (<img className={classes.subMenuImage} src="/images/products/top/cake.jpg" alt=""></img>)
            this.setState({});
        }
    }

    hideSubToolbar = () => {
        this.setState({hideSubToolbar: true}, () => {
            setTimeout(() => 
                this.setState({hideSubToolbar: false}), 100
            )
        })
    }

    render() {

        let submenu = null;
        let toggleBurger = this.props.toggleBurger ?  {
            onClick: () => this.props.toggleBurger() 
            } : {}
        if (this.state.hideSubToolbar && !this.submenuClasses.includes(classes.Hide)) this.submenuClasses.push(classes.Hide)
        else this.submenuClasses = this.submenuClasses.filter(item => item != classes.Hide)

        // Toolbar images in submenu dropdown
        if (this.props.navSubItems) {
            let subMenuColumns = [[], []];

            this.props.navSubItems.map((item, index) => {

                let i = Object.entries(item)[0]
                let columnCount = window.innerWidth >= 750 ? 2 : 1;
                let subListAttributes = window.innerWidth >= 750 ? {
                    onMouseEnter: () => this.updateSubToolbarImage(i[1]),
                    onMouseLeave: () => this.updateSubToolbarImage(null),
                    onClick: () => this.hideSubToolbar()
                } : {};

                
                subMenuColumns[index % columnCount].push(
                    <><li className={classes.SubListItem}
                        key={index}
                        {...subListAttributes} >
                        <Link
                            {...toggleBurger}
                            to={"/Products/" + i[0]}>
                            <span className={classes.NavSubAnchor} >
                                {i[0]}
                            </span>
                        </Link>
                    </li></>
                )
                return null;
            })

            // 
            submenu = (
                <div className={this.submenuClasses.join(" ")}>
                    <div className={classes.SubList}>
                        <div className={classes.SubListItemContainer}>
                            {
                                subMenuColumns.map((item, index) => (
                                    <ul key={index} className={classes.subMenuColumn}>{item}</ul>
                                ))
                            }
                        </div>
                        <div className={classes.SubMenuImageContainer}>
                            {this.subToolBarElement}
                        </div>
                    </div>
                </div>
            )
        }

        if (this.props.navItemStyling && !this.anchorLinkClasses.includes(classes.Button)) 
                this.anchorLinkClasses.push(classes.Button)
        
        if (this.props.navSubItems && !this.anchorLinkClasses.includes(classes.ToggleSubMenu)) 
            this.anchorLinkClasses.push(classes.ToggleSubMenu)

        return (
            <Aux>
                <Link 
                    {...toggleBurger}
                    to={"/" + this.props.navItemName}>

                    <span
                        className={this.anchorLinkClasses.join(" ")}
                        key={this.props.navKey}>
                        {this.props.navItemIcon ? <img src={this.props.navItemIcon} className={classes.Icon} /> : null}
                        {this.props.navItemName}
                    </span >
                </Link>
                {submenu}
            </Aux >
        )
    }
}

export default navigationItem;

