import React, { Component } from 'react'

import Aux from '../../Hoc/Aux'
import classes from './About.module.scss'
import Title from '../Content/Title/Title'
import PageData from '../../assets/Data/Page-Data.json'

class About extends Component {
    constructor(props) {
        super(props);
        this.product = PageData.Pages["About"];
    }

    componentDidMount() {
        window.scrollTo(0, 0)
      }
      
    render() {

        return (
            <Aux>
                <div className={classes.TitleContainer}>
                <h1 id="Hero" className={classes.Title}>About Camille</h1>
                </div>
                <section className={classes.BioSection}>
                    <div className={classes.BioSectionOuter}>
                        <div className={classes.BioSectionInner}>
                            <div className={classes.BioImageContainer}>
                                <img className={classes.BioImage} src='/images/bio/Camille-Bio-Photo.jpg' alt="" />
                            </div>
                            <div className={classes.BioDescription}>
                                <div><p>Camille began baking in 2014 when she had trouble finding a cake for her son’s 2nd birthday. She was looking for something kid friendly and that wasn’t filled with preservatives and unnatural ingredients. Rather than spending countless hours scouring the internet and calling bakery after bakery, she decided to try her hand at cake making. Decades of cooking experience proved the cake a hit at her son’s birthday party. Soon after sharing with friends and family that the cake was homemade, Camille began getting requests for birthday parties and gatherings. She quickly learned to bake many classic recipes and plenty of unconventional recipes alike. She continues taking orders and expanding her repertoire while finishing her education in Nursing.</p></div>
                            </div>
                        </div>
                    </div>
                </section>
            </Aux>
        )
    }
}

export default About;