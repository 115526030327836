import React, { Component } from 'react';
import classes from './Toolbar.module.scss';
import Hamburger from './Hamburger/Hamburger'
import NavigationItems from './NavigationItems/NavigationItems';
import Logo from './Logo/Logo';
import ToolbarItems from '../../assets/Data/Toolbar-Data.json'
import Aux from '../../Hoc/Aux'

const navItems = ToolbarItems;
let LogoHeight = "100";
let fill = null;
let scrollPosition = 0;
let scrollingDown = false;

let logo = (
    <Logo
        className={classes.Logo}
        height={LogoHeight + "px"}
    />);

class Toolbar extends Component {

    componentDidMount() {
        this.updateBurgerState();
        window.addEventListener('resize', this.updateBurgerState.bind(this));
    }

    state = {
        showBurgerMenu: false,
        burgerIconDisplayed: window.innerWidth < 750,
        showToolbar: true
    }

    updateBurgerState() {
        if (window.innerWidth >= 750)
            this.hideBurgerMenu();
        else this.displayBurgerIcon();
    }

    toggleBurger = () => {
        this.setState((prevState) => {
            return { showBurgerMenu: !prevState.showBurgerMenu }
        });
    }

    hideBurgerMenu = () => {
        this.setState({ showBurgerMenu: false, burgerIconDisplayed: false });
    }

    displayBurgerIcon = () => {
        this.setState({ burgerIconDisplayed: true });
    }

    render() {

        let toolbarContainerClasses = [classes.ToolbarContainer];
        let toolbarClass = classes.Toolbar;

        if (this.state.showToolbar || this.state.burgerIconDisplayed) {
            toolbarContainerClasses = [classes.ToolbarContainer]
            toolbarClass = classes.Toolbar
        }

        let navigationItems = (
            <NavigationItems
                navItems={navItems}
                // centeredLogo={!this.state.burgerIconDisplayed}
                centeredLogo={false}
                sideMenuIsOpen={this.state.showBurgerMenu}
                burgerIconDisplayed={this.state.burgerIconDisplayed}
                toggleBurger={this.toggleBurger}>
                {logo}
            </ NavigationItems>
        )

        let mobileMenu = this.state.burgerIconDisplayed ?
            (
                <div>
                    <div className={[classes.MobileToolbarItem, classes.Left].join(" ")}>
                        <Hamburger
                            toggleBurger={this.toggleBurger}
                            sideMenuIsOpen={false}
                        />
                    </div>
                    <div className={[classes.MobileToolbarItem, classes.Center, classes.Logo].join(" ")}>
                        <Logo height={"70px"} />
                    </div>
                    {navigationItems}
                </div>

            ) : (
                <>
                {navigationItems}
                </>
            )

        return (
            <Aux>
                {fill}
                <div className={toolbarContainerClasses.join(" ")}>
                    <header className={toolbarClass} >
                        {mobileMenu}
                    </header>
                </div>
            </Aux>
        )
    }
}

export default Toolbar;