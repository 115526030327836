import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';
import ContactPage from './Components/Contact/ContactPage';
import Banner from './Components/Banner/Banner'
import Home from './Components/Home/Home'
import About from './Components/About/About'
import Footer from './Components/Footer/Footer';
import Toolbar from './Components/Toolbar/Toolbar';
import MiniToolbar from './Components/Toolbar/MiniToolbar';
import Products from './Components/Content/Product/Products';
import Gallery from './Components/Gallery/Masonry/Gallery';
import Packages from './Components/Packages/Packages'
import classes from './App.module.scss';
import Reviews from './Components/Reviews/Reviews'
import { Redirect } from 'react-router';

import ReactGA from 'react-ga';
ReactGA.initialize('UA-172637382-2');
ReactGA.pageview(window.location.pathname + window.location.search);

class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      dimBackground: false,
      showBanner: true
    }
  }

  CloseBanner = () => {
    this.setState({ showBanner: false });
  }

  dimBackgroundHandler = () => {
    this.setState(prev => (
      { dimBackground: !prev.dimBackground }));
  }

  render() {
    let dimBG = this.state.dimBackground === true ? classes.Dim : null
    
    return (
      <BrowserRouter>
        <div className={classes.App}>
          {this.state.showBanner ? <Banner clickCloseHandler={this.CloseBanner}></Banner> : null}
          <Toolbar dimBackground={this.dimBackgroundHandler}></Toolbar>
          <MiniToolbar dimBackground={this.dimBackgroundHandler}></MiniToolbar>
          <div className={dimBG}>
            <Switch>
              <Route exact path="/" render={(props) => <Home {...props}/>}></Route>
              <Route path="/About" render={(props) => <About {...props} />}></Route>
              <Route path="/Gallery" render={(props) => <Gallery {...props} />}></Route>
              <Route exact path="/Products/" render={(props) => <Products {...props} />}></Route>
              <Route path="/Products/:product" render={(props) => <Products {...props} />}></Route>
              <Route path="/Packages" render={(props) => <Packages {...props} />}></Route>
              <Route path="/Reviews" render={(props) => <Reviews {...props} />}></Route>
              <Route path="/Contact" render={(props) => <ContactPage {...props} />}></Route>
              <Redirect to="/" />
            </Switch>
            <Footer></Footer>
          </div>
        </div>
      </BrowserRouter>
    );
  }
}
export default App;
