import React, { useState } from "react";

import FileImport from '../FileImport/FileImport2';
import ReviewCard from './ReviewCard';
import axios from 'axios';

import { Row, Col, Form, FormGroup, Input, Label, Button } from 'reactstrap';
import AvatarPicker from "../Avatar/AvatarPicker";
import Loader from '../Loader/Loader';

import classes from './ReviewForm.module.scss'
export default function ReviewForm(props) {

    const getTodaysDate = () => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();
        return mm + '/' + dd + '/' + yyyy;
    }

    let [avatarID, setAvatarID] = useState(1)
    let [formData, setFormData] = useState({ name: "", email: "", product: "", title: "", description: "", imageData: "", avatar: 1 })

    let [blobData, setBlobData] = useState([]);
    let [newReview, setNewReview] = useState(null);
    let [reviewLoading, setReviewLoading] = useState(false);

    let handleSubmit = async e => {
        e.preventDefault();

        setReviewLoading(true);
        props.newReviewLoading(true);

        const imageUploadData = blobData.map((item) => {
            return { "file": item.file, "type": item.type }
        })

        const uploadUrl = 'https://8dp6pie6n4.execute-api.us-west-1.amazonaws.com/dev/post-review'

        const body = {
            "avatar": avatarID,
            "description": formData.description,
            "product": formData.product,
            "title": formData.title,
            "name": formData.name,
            "email": formData.email,
            "images": imageUploadData
        }

        const response = await axios.post(uploadUrl, body).then((res) => {
            console.log(res)
            return res;
        }).then((res) => {
            setTimeout(() => {
                setNewReview(res.data.res)
                props.newReviewLoading(false);

            }, 7000)
        })

    }

    let handleChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    return (
        <>
            {!newReview ?
                <>
                    <h2 className={classes.ReviewRequestFormHeading}>How are we doing?</h2>
                    <p className={classes.ReviewRequestFormSubHeading}>Tell us about your experience</p>
                    <div className={classes.ReviewForm}>
                        <AvatarPicker setAvatarID={setAvatarID}></AvatarPicker>
                        <Form onSubmit={handleSubmit}>
                            <Row form>
                                <Col md={6}>
                                    <FormGroup className="px-1">
                                        <Label for='name'>Name:</Label>
                                        <Input
                                            value={formData.name}
                                            type="text"
                                            name='name'
                                            onChange={handleChange}>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup className="px-1">
                                        <Label for='email'>Email:</Label>
                                        <Input
                                            value={formData.email}
                                            type="email"
                                            name='email'
                                            onChange={handleChange}>
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row form>
                                <Col md={6}>
                                    <FormGroup className="px-1">
                                        <Label for='title'>Title:</Label>
                                        <Input
                                            required
                                            value={formData.title}
                                            placeholder="Review Title"
                                            type="text"
                                            name='title'
                                            onChange={handleChange}>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup className="px-1">
                                        <Label for='product'>Items or Packages purchased:</Label>
                                        <Input
                                            required
                                            value={formData.product}
                                            type="text"
                                            name='product'
                                            onChange={handleChange}>
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup className="px-1">
                                        <Label for='description'>Description:</Label>
                                        <Input
                                            required
                                            placeholder="Provide a brief description"
                                            value={formData.description}
                                            type="textarea"
                                            name='description'
                                            onChange={handleChange}>
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <FormGroup>
                                <Label style={{ padding: 5 }} for='description'>Share Images:</Label>
                                <FileImport setUploadFiles={setBlobData}></FileImport>
                            </FormGroup>

                            <div className={classes.ButtonContainer}>
                                {reviewLoading ? <Loader></Loader> :
                                    <>
                                        <Button className={`${classes.Button} ${classes.Submit}`}>Submit feedback!</Button>
                                        <Button className={`${classes.Button} ${classes.Cancel}`} onClick={props.closeButtonHandler}>Cancel</Button>
                                    </>
                                }
                            </div>

                        </Form>
                    </div>
                </>
                :
                <>
                    <h2 className={classes.ReviewRequestFormHeading}>Thank you</h2>
                    <p className={`${classes.ReviewRequestFormSubHeading} ${classes.LightPadding}`}>Here's a preview of your review. Someone from our team will be reviewing it shortly.</p>
                    <ReviewCard
                        avatar={newReview.Avatar}
                        name={newReview.Name}
                        product={newReview.Product}
                        title={newReview.Title}
                        date={newReview.Date}
                        description={newReview.Description}
                        images={newReview.Images}

                    />
                </>
            }
        </>
    )
}
