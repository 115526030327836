import React, { Component } from 'react';
import classes from "./PackagesHero.module.scss";

import Markdown from 'markdown-to-jsx';
import PageData from '../../assets/Data/Packages-Page-Data.json'

class PackagesHero extends Component {

    componentDidMount() {
        window.addEventListener('resize', this.updateScreenWidth.bind(this));
        window.scrollTo(0, 0)
    }

    state = {
        screenWidth: window.innerWidth
    }

    updateScreenWidth = () => {

        this.setState({
            screenWidth: window.innerWidth
        })
    }
    render() {


        let heroSection = null;
        let bgStyle = {
            backgroundImage: `url(${PageData.HeroBG})`
        }

        if (this.state.screenWidth < 800) {
            heroSection = (
                <>
                    <div className={classes.TitleContainer} id="Hero">
                        <Markdown className={classes.Title} children={PageData.Title}></Markdown>
                    </div>
                    <div className={classes.Container}>
                        <div className={classes.Bg}>
                            <div className={classes.BgImage} style={bgStyle} />
                        </div>
                    </div>

                    <div className={classes.TitleAndSubContainer}>
                        <Markdown
                            options={{ forceBlock: true }}
                            className={classes.SubTitleD}
                            children={PageData.SubTitle} />
                        <Markdown
                            options={{ forceBlock: true }}
                            className={classes.TagLineD}
                            children={PageData.TagLine} />
                        <div className={classes.WavyDivider}>
                            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                                <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className={classes.ShapeFill}></path>
                            </svg>
                        </div>
                    </div>
                </>
            )
        }
        else {
            heroSection = (
                <>
                    <div className={classes.HeroContainer} id="Hero">
                        <div className={classes.Hero} style={bgStyle}>
                            <div className={classes.TitleAndSubContainer}>
                                <Markdown
                                    options={{ forceBlock: true }}
                                    className={classes.TitleD}
                                    children={PageData.Title} />
                                <Markdown
                                    options={{ forceBlock: true }}
                                    className={classes.SubTitleD}
                                    children={PageData.SubTitle} />
                                <Markdown
                                    options={{ forceBlock: true }}
                                    className={classes.TagLineD}
                                    children={PageData.TagLine} />
                            </div>
                            <div className={classes.WavyDivider}>
                                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                                    <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className={classes.ShapeFill}></path>
                                </svg>
                            </div>
                        </div>
                    </div>

                </>
            )
        }

        return (
            <>
                {heroSection}
            </>

        );
    }
};

export default PackagesHero;