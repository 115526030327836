import React, { useState, useEffect, useRef } from "react";
import classes from "./FileImport.module.scss";

import Dropzone from "react-dropzone";
import gsap from 'gsap';

export default function FileImport(props) {

    const allowedFileTypes = ['jpg', 'jpeg', 'png'];

    let idIterator = useRef(0);
    const [files, setFiles] = useState([]);
    const [maxFilesExceeded, setMaxFilesExceeded] = useState(false);

    useEffect(() => {
        props.setUploadFiles(files)
    }, [files])

    const handleDrop = async fileList => {
        const result = await fileListToBlob(fileList);
        if (files.length + fileList.length > 5)
            setMaxFilesExceeded(true)
        const firstFiveFiles = [...files, ...result].slice(0, 5);
        setFiles(firstFiveFiles);
    }


    let arrayRef = []
    const removeThumbnail = (arrayRefIndex, id) => {
        const tl = gsap.timeline({ paused: true })
        tl.to(arrayRef[arrayRefIndex], { width: 0, height: 0, duration: .15, ease: gsap.Power2 })
            .add(() => {
                for (let j = 0; j < files.length; j++) {
                    if (files[j].id == id) {
                        let arr = [...files];
                        arr.splice(j, 1)
                        setFiles(arr);
                    }
                }
            }).play();
    }

    async function fileListToBlob(fileList) {

        // here will be array of promisified functions
        const promises = []

        // loop through fileList with for loop
        for (let i = 0; i < fileList.length; i++) {
            promises.push(getBase64(fileList[i]))
        }

        // array with base64 strings
        return await Promise.all(promises)
    }


    // function getBlob(file) {
    //     const reader = new FileReader()

    //     return new Promise(resolve => {
    //         reader.onload = ev => {
    //             let image = null;
    //             let imageType = null;

    //             imageType = ev.target.result.split(':image/').pop().split(';')[0];
    //             image = ev.target.result;

    //             let binary = atob(image.split(',')[1])
    //             let array = []
    //             for (var i = 0; i < binary.length; i++)
    //                 array.push(binary.charCodeAt(i))
    //             let blobData = new Blob([new Uint8Array(array)], { type: `image/${imageType}` })

    //             resolve({
    //                 id: idIterator.current++,
    //                 file: blobData,
    //                 imageType: imageType,
    //                 previewData: Object.assign(file, {
    //                     preview: URL.createObjectURL(file),
    //                 })
    //             })
    //         }
    //         reader.readAsDataURL(file)
    //     })
    // }

    function getBase64(file) {
        const reader = new FileReader()

        return new Promise(resolve => {
            reader.onload = ev => {
                let image = null;
                let imageType = null;

                imageType = ev.target.result.split(':image/').pop().split(';')[0];
                image = ev.target.result.split(',')[1];

                resolve({
                    id: idIterator.current++,
                    file: image,
                    type: imageType,
                    previewData: Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                })
            }
            reader.readAsDataURL(file)
        })
    }

    // useEffect(() => () => {
    //     console.log(files)
    //     // Make sure to revoke the data uris to avoid memory leaks
    //     setFiles(files.forEach(file => URL.revokeObjectURL(file.preview)));

    //     console.log(files)

    // }, [files]);


    const thumbs = files.map((file, index) => {
        const prev = file.previewData;
        return (
            <div
                ref={el => arrayRef[index] = el}
                className={classes.Thumb} key={file.id}>
                <div className={classes.ThumbInner}>
                    <img
                        src={prev.preview}
                        className={classes.Img}
                    />
                    <div
                        onClick={() => {
                            removeThumbnail(index, file.id)
                        }}
                        className={classes.ThumbInnerClose}>x</div>
                </div>
            </div>
        )
    });

    return (
        <>
            <Dropzone
                onDrop={handleDrop}
                accept="image/*"
                minSize={1024}
                maxSize={1024 * 1024 * 9}
                maxFiles={5}
            >
                {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps({ className: classes.Dropzone })}>
                        <input {...getInputProps()} />
                        
                        <img
                            className={classes.ImageUploadIcon}
                            src="/images/icons/image-file-icon.png"
                            alt="" />
                        <span className={classes.DropZoneText}>
                            <b>Drag and drop files</b> or <b>click to select files</b>
                        </span>
                    </div>
                )}

            </Dropzone>
            {thumbs ?
                <>
                    <p></p>
                    <aside className={classes.ThumbsContainer}>
                        {thumbs}
                    </aside>
                </>
                :
                null}
        </>
    );
}
