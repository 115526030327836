import React from 'react';
import classes from './Banner.module.scss'

const banner = (props) => {

    return (
        <div className={classes.Banner} >
            <div className={classes.Column}><span><b>Free</b> delivery to South Bay,  Central, and  North Central <b>San Diego</b> with any purchase over $99</span></div>
            <div 
                onClick={props.clickCloseHandler} 
                className={classes.Close}><span>x</span></div>
        </div>
    )
}

export default banner;