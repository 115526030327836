import React, { Component } from 'react'
import ProductGrid from './ProductGrid';
import ProductsHero from './ProductsHero';
import PageData from '../../../assets/Data/Page-Data.json'
import _ from 'lodash';

import { Redirect } from 'react-router';


class Products extends Component {
    constructor(props) {
        super(props);
        this.paramsExist = (this.props.match.params.product !== undefined);
        this.paramFound = !this.paramsExist ?
            false :
            (Object.keys(PageData.Pages).indexOf(this.props.match.params.product)) > 0 ?
                true : false

        this.state = {
            productPage: this.paramFound ?
                PageData.Pages[this.props.match.params.product] : PageData.Pages["Products"],
            reRoute: this.paramsExist && !this.paramFound,
            screenWidth: window.innerWidth
        }
    }

    componentDidMount() {
        // window.addEventListener('resize', this.updateScreenWidth.bind(this));
        window.scrollTo(0, 0)
    }

    componentDidUpdate = () => {

        this.paramsExist = (this.props.match.params.product !== undefined);
        this.paramFound = !this.paramsExist ?
            false :
            (Object.keys(PageData.Pages).indexOf(this.props.match.params.product)) > 0 ?
                true : false


        if (!this.paramFound) {
            // console.log("CDU params not found")
            // console.log(_.isEqual(this.state.productPage, PageData.Pages["Products"]))
            // console.log(this.state.productPage)
            // console.log(PageData.Pages["Products"])
            if (!_.isEqual(this.state.productPage, PageData.Pages["Products"])) {

                this.setState(prevState => ({
                    ...prevState,
                    productPage: PageData.Pages["Products"],
                    reRoute: true
                }))
            }
            else if (this.state.reRoute) {
                this.setState(prevState => ({
                    ...prevState,
                    reRoute: false
                }))
            }
        }
        else {
            if (!_.isEqual(this.state.productPage, PageData.Pages[this.props.match.params.product])) {
                this.setState(prevState => ({
                    ...prevState,
                    productPage: PageData.Pages[this.props.match.params.product],
                    reRoute: false
                }))
            }
        }
    }


    updateScreenWidth = () => {

        this.setState(prevState => ({
            ...prevState,
            screenWidth: window.innerWidth
        }))
    }

    render() {
        if (this.state.reRoute) {
            return <Redirect to={"/Products"} />
        }
        else {
            return (
                <>
                    <ProductsHero page={this.state.productPage} />

                    <ProductGrid ProductsPage={!this.paramsExist} Photos={this.state.productPage.Photos}></ProductGrid>
                </>
            )
        }
    }
}

export default Products;